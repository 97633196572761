/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'sign-turn-slight-left-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098zM6.864 8.368a.25.25 0 01-.451-.039l-1.06-2.882a.25.25 0 01.192-.333l3.026-.523a.25.25 0 01.26.371l-.667 1.154.621.373A2.5 2.5 0 0110 8.632V11H9V8.632a1.5 1.5 0 00-.728-1.286l-.607-.364-.8 1.386z"/>',
    },
});
